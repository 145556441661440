import type { IAPIJobForUser } from '@hokify/common';

export const shareJob = function shareJob(job: IAPIJobForUser) {
	const nuxt = tryUseNuxtApp();
	if (nuxt) {
		nuxt.$trackUserEvent?.('share_job', { item_id: job._id });
	}

	if (process.env.cordova && window.plugins?.socialsharing) {
		const options = {
			message: job && job.name,
			subject: 'Job auf hokify gefunden',
			url: job && job.webUrl
		};
		window.plugins.socialsharing.shareWithOptions(options);
	} else if (navigator.share) {
		navigator.share({
			title: 'Job auf hokify gefunden!',
			text: job && job.name,
			url: job && job.webUrl
		});
	} else {
		window.open(job && job.webUrl, '_blank');
	}
};
